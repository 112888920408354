<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot class="header">
              <h5 class="modal-title">Delete Account?</h5>
            </slot>
            <button type="button" class="btn-close" data-dismiss="modal" @click="$emit('close')">
            </button>
          </div>
          <slot name="body">
            <div class="modal-body">
              <h6>This is irreversible. {{ deleteMessageUser }} will no longer be able to sign in or access Project Para
                content.</h6>
              <span>You'll permanently delete:</span>
              <ul>
                <li>Project Para Profile</li>
                <li>Course Data for Project Para</li>
              </ul>
              <span>Please follow up with the third party providers to ensure your data is removed:</span>
              <ul>
                <li><a href="https://www.instructure.com/policies/ccpa" target="_blank">Canvas LMS</a></li>
                <li><a href="https://www.instructure.com/policies/privacy-badgr" target="_blank">Badgr</a></li>
              </ul>
              <span>Type <span style="font-weight: bold;">DELETE</span> to confirm</span>
              <div class="row">
                <div class="col-sm-6">
                  <input class="form-control" v-model="deleteConfirmText" required/>
                </div>
              </div>
              <div style="text-align: center;">
                <button class="button btn-danger bi bi-trash-fill" :disabled="deleteButtonDisabled"
                        :style="deleteButtonStyle" @click="deleteUserHelper">Delete
                </button>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {computed, ref} from 'vue';
import {deleteUser} from "@/Helpers/ApiCalls/UserAPICalls";
import Swal from "sweetalert2";
import axios from "axios";
import {useStore} from "vuex";
import router from "@/router";

export default {
  name: "UserDeleteModal",
  props: {
    idUser: String,
    userName: String,
    show: Boolean,
    editingUserId: Number
  },
  setup(props) {
    const deleteConfirmText = ref("");
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];

    const deleteButtonDisabled = computed(() => {
      return deleteConfirmText.value !== "DELETE";
    })

    const deleteMessageUser = computed(() => {
      return props.editingUserId === parseInt(atob(props.idUser)) ? "You" : props.userName
    })

    async function deleteUserHelper() {
      await axios.delete(deleteUser(parseInt(atob(props.idUser))), {
        params: {
          sendDataInfo: false,
        },
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Deleted",
            confirmButtonText: "Okay"
          }).then(() => {
            if (props.editingUserId === parseInt(atob(props.idUser))) {
              store.dispatch('auth/signOut');
            } else
              router.push({name: 'AdminDashboard'})
          })
        }
      })
    }

    const deleteButtonStyle = computed(() => {
      return deleteButtonDisabled.value ? "background-color: lightgrey; color: black;" : "";
    })

    return {
      deleteConfirmText,
      deleteButtonDisabled,
      deleteButtonStyle,
      deleteMessageUser,
      deleteUserHelper
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  /*z-index: 9998;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.modal-container {
  max-width: 800px;
  width: 80%;
  height: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}


.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

input {
  border: 1px solid black;
}
</style>