<template>
    <div>
        <label for="selectedCountry">Country<span style="color: red;">*</span></label>
        <select class="form-select" v-model="selectedCountry" @change="changeVal($event)" id="selectedCountry"
                :disabled="countryDisabled" style="height: 41px;" :tabindex="tabIdx">
            <option value="-1">Select a Country</option>
            <option
                    v-for="country in countries"
                    :value="country.countryCode"
                    :key="country.idCountry">
                {{ country.countryName }}
            </option>
        </select>
        <transition name="fade">
            <div v-if="selectedCountry === 'US' && showState">
                <StateSelectComposition
                        :idEsu="selectedEsu"
                        :idDistrict="selectedDistrict"
                        :idSchool="selectedSchool"
                        :selfRegistering="selfRegistering"
                        :showDistrict="showDistrict"
                        :showSchool="showSchool"
                        :esuDisabled="disabledEsu"
                        :districtDisabled="districtDisabled"
                        :schoolDisabled="schoolDisabled"
                        :stateDisabled="stateDisabled"
                        :defaultState="defaultState"
                        :showEsu="showEsu"
                        @selectedState="updateState($event)"
                        @selectedEsu="updateEsu($event)"
                        @selectedDistrict="updateDistrict($event)"
                        @selectedSchool="updateSchool($event)"
                        :tab-idx="tabIdx + 1"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import StateSelectComposition from "@/components/LocationSelect/StateSelectComposition";
import {API_URL} from "../../../Constants";
import axios from "axios";
import {onBeforeMount, ref, watch} from "vue";

export default {
	name: "CountrySelectComposition",
	components: {StateSelectComposition},
	props: {
		idEsu: Number,
		idDistrict: Number,
		idSchool: Number,
		selfRegistering: Boolean,
		showDistrict: Boolean,
		showSchool: Boolean,
		esuDisabled: Boolean,
		districtDisabled: Boolean,
		schoolDisabled: Boolean,
		stateDisabled: Boolean,
		countryDisabled: Boolean,
		defaultState: String,
		defaultCountry: String,
		showEsu: Boolean,
		showState: Boolean,
		tabIdx: {
			type: Number,
			required: false,
			default: 1,
		}
	},
	emits: ["selectedCountry", "selectedState", "selectedEsu", "selectedDistrict", "selectedSchool"],
	setup(props, context) {
		let selectedCountry = ref("");
		let selectedState = ref("");
		let countries = ref({});
		let selectedEsu = ref(props.idEsu !== undefined ? props.idEsu : -1);
		let selectedDistrict = ref(props.idDistrict !== undefined ? props.idDistrict : -1);
		let selectedSchool = ref(props.idSchool !== undefined ? props.idSchool : -1);
		let disabledEsu = ref(false);

		watch(() => props.esuDisabled, (first, second) => {
			disabledEsu.value = props.esuDisabled;
		})

		watch(() => props.idEsu, (first, second) => {
			selectedEsu.value = props.idEsu;
		})

		watch(() => props.idDistrict, (first, second) => {
			selectedDistrict.value = props.idDistrict;
		})

		watch(() => props.idSchool, (first, second) => {
			selectedSchool.value = props.idSchool;
		})

		watch(() => props.defaultCountry, (first, second) => {
			selectedCountry.value = props.defaultCountry;
		})

		watch(() => props.defaultState, (first, second) => {
			selectedState.value = props.defaultState;
		})

		onBeforeMount(() => {
			getCountries();
			selectedCountry.value = props.defaultCountry;
			if (props.idEsu !== -1) {
				selectedEsu.value = props.idEsu
			}

			if (props.idDistrict !== -1) {
				selectedDistrict.value = props.idDistrict;
			}

			if (props.idSchool !== -1) {
				selectedSchool.value = props.idSchool;
			}
		})

		function setSelectedValues() {
			if (props.idEsu !== selectedEsu.value) {
				selectedEsu.value = props.idEsu
			}

			if (props.idDistrict !== selectedDistrict.value) {
				selectedDistrict.value = props.idDistrict;
			}

			if (props.idSchool !== selectedSchool.value) {
				selectedSchool.value = props.idSchool;
			}
		}

		async function getCountries() {
			await axios.get(API_URL + "/countries/all")
				.then((result) => {
					countries.value = result.data;
				})
		}

		function changeVal(event) {
			context.emit(event.target.id, event.target.value);
		}

		function updateState(event) {
			context.emit("selectedState", event);
		}

		function updateEsu(event) {
			context.emit("selectedEsu", event);
		}

		function updateDistrict(event) {
			context.emit("selectedDistrict", event);
		}

		function updateSchool(event) {
			context.emit("selectedSchool", event);
		}


		return {
			selectedCountry,
			selectedEsu,
			selectedDistrict,
			selectedSchool,
			countries,
			disabledEsu,
			changeVal,
			updateState,
			updateEsu,
			updateDistrict,
			updateSchool
		}
	}
}
</script>

<style scoped>

</style>