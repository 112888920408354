<template>
  <div class="row">
    <div class="col">
      <h1 style="color: black;">Edit User</h1>
    </div>
  </div>
  <!--    <h1 style="color: black;">Edit User</h1>-->
  <div class="row">
    <div class="col">
      <label>First Name<span>*</span></label>
      <input class="form-control" v-model="user.firstName" style="height: 41px;" tabindex="1"/>
    </div>
    <div class="col">
      <country-select-composition
          :show-esu="false"
          :show-state="false"
          :country-disabled="locationDisabled"
          :default-country="user.countryCode"
          @selectedCountry="changeCountry($event)"
          :tab-idx="6"
      />
    </div>
    <div class="col">
      <label>{{ enrollmentLabel }}</label>
      <br/>
      <input type="checkbox" v-model="updateEnrollment" @change="changeEnrollmentHelper(updateEnrollment)"
             v-if="enrollmentCheckBoxEnabled"
             style="border: 1px solid black;"/>
      <input type="checkbox" :checked="user.statusFlag.statusCode === 'A'"
             v-else :disabled="true" style="border: 1px solid black;"/>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label>Last Name<span>*</span></label>
      <input class="form-control" v-model="user.lastName" style="height: 41px;" tabindex="2"/>
    </div>
    <div class="col">
      <div v-if="user.countryCode === 'US'">
        <state-select-composition
            :show-esu="false"
            :state-disabled="locationDisabled"
            :default-state="user.stateCode"
            @selectedState="changeState($event)"
            :tab-idx="7"
        />
      </div>
    </div>

    <div class="col">
      <div v-if="user.registrationDate !== undefined && user.registrationDate !== null">
        <label>Registration Date</label>
        <p><span style="color: black; font-weight: normal">{{ user.registrationDate }}</span></p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label>Job Title<span>*</span></label>
      <job-type-dropdown
          :editing-existing="true"
          :show-esucc="editingUser.roleHierarchyId === 1"
          :show-esu-admin="showEsuAdmin"
          :show-district-admin="showDistrictAdmin"
          :show-instructor="showInstructor"
          :show-nde="editingUser.roleHierarchyId === 1"
          :job-id="user.roleHierarchyId"
          @roleHierarchy="changeRoleHierarchy($event)"
          :is-disabled="selfEditing"
          :tab-idx="4"
      />
    </div>
    <div class="col">
      <div v-if="user.stateCode === 'NE'">
        <esu-select-composition
            :id-esu="user.idEsu"
            :id-district="user.idDistrict"
            :id-school="user.idSchool"
            :show-district="false"
            :show-school="false"
            :esu-disabled="districtDisabled"
            :district-disabled="districtDisabled"
            :school-disabled="schoolDisabled"
            @selectedEsu="changeEsu($event)"
            @selectedDistrict="changeDistrict($event)"
            @selectedSchool="changeSchool($event)"
            :tab-idx="8"
        />
      </div>
      <div v-else>
        <OutNeOrganizationDropdown
            :default-organization="user.outStateOrgName"
            :state-code="user.stateCode"
            :country-code="user.countryCode"
            @educationalInstitution="changeOutState($event)"
            tabindex="8"
        />
      </div>
    </div>
    <div class="col">
      <label>Last Login</label>
      <p v-if="user.lastLoginDate !== undefined && user.lastLoginDate !== null">{{
          (user.lastLoginDate !== null && user.lastLoginDate !== undefined) ? user.lastLoginDate : "Has Not Logged In Yet"
        }}</p>
      <p v-else>
        Has not logged into Project Para yet
      </p>

    </div>
  </div>
  <div class="row">
    <div class="col">
      <label>Email<span>*</span></label>
      <input class="form-control" v-model="user.email" style="height: 41px;" tabindex="4"/>
      <transition name="fade">
                  <span v-if="msg.email">{{ msg.email }}
                  <br/>
                  </span>
      </transition>
    </div>
    <div class="col">
      <div v-if="showDistrict">
        <district-select-composition
            :id-district="user.idDistrict"
            :id-school="user.idSchool"
            :id-esu="user.idEsu"
            :show-school="false"
            @selectedDistrict="changeDistrict($event)"
            @selectedSchool="changeSchool($event)"
            :tab-idx="9"
        />
      </div>
    </div>
    <div class="col">
      <label>Account Activated On</label>
      <p style="color: black; font-weight: normal;"
         v-if="user.enabledDate !== undefined && user.enabledDate !== null">{{ user.enabledDate }}</p>
      <p v-else>03/01/2022</p>
    </div>
  </div>
  <div class="row">
    <div class="col" v-if="showCanvasIdSection && user.canvasId !== null">
      <label>User's Canvas Id
        <b-tooltip target="_self" title="Only change this if a user's canvas ID in our system does not appear to line up with the one in Canvas."><i
            class="bi-info-circle"></i></b-tooltip>
      </label>
      <input class="form-control" style="height: 41px;" v-model="user.canvasId"/>
    </div>

    <div class="col" v-else>

    </div>
    <div class="col">
      <div v-if="showSchool">
        <school-select-composition
            :id-school="user.idSchool"
            :id-district="user.idDistrict"
            @selectedSchool="changeSchool($event)" :tab-idx="10"/>
      </div>
    </div>
    <div class="col">
      <div v-if="!selfEditing || editingUser.roleHierarchyId === 1">
        <label>Account Status</label>
        <StatusSelect :rerender="rerenderAccountStatus" :default-status="user.statusFlag.statusCode"
                      @selectedStatus="userStatusHelper($event)"/>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col">
      <button class="button btn-danger bi bi-trash" @click="deleteButtonClicked" v-if="showDeleteButton"> Delete
      </button>
      <button class="button btn-info bi bi-x-circle" @click="cancelEdit" v-else> Cancel
      </button>
    </div>
    <div class="col">
      <button class="button btn-primary" @click="submitEdit" :disabled="!canSubmit" tabindex="15">Save Changes
      </button>
    </div>
    <div class="col">
      <div v-if="showChangePasswordButton">
        <button class="button btn-primary" @click="showChangePasswordModal = true">Change Password
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm">
    </div>
    <div class="col-sm">
      <button v-if="editingUser.roleHierarchyId === 1 && showDeleteButton"
              class="button btn-primary bi-link-45deg"
              @click="routeToUserMerge">Merge User Into Another
      </button>
    </div>
    <div class="col-sm">

    </div>
  </div>
  <div v-if="showDeleteModal">
    <Teleport to="body">
      <UserDeleteModal
          :id-user="idUser"
          :show="showDeleteModal"
          @close="showDeleteModal = false"
          :user-name="`${user.firstName} ${user.lastName}`"
          :editing-user-id="editingUser.idUser"
      />
    </Teleport>
  </div>
  <div v-if="showChangePasswordModal">
    <Teleport to="body">
      <PasswordResetComponent :user-email="user.email" :show="showChangePasswordModal"
                              :resetting-on-login="false"
                              :admin-changing="!selfEditing"
                              @close="showChangePasswordModal = false"
                              @retry="retryPasswordReset"
      />
    </Teleport>
  </div>
  <div v-if="isLoading">
    <Teleport to="body">
      <LoadingModal :show="isLoading" />
    </Teleport>
  </div>

</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {useStore} from "vuex";
import {API_URL} from "../../../Constants";
import axios from "axios";
import JobTypeDropdown from "@/components/JobType/JobTypeDropdown";
import StateSelectComposition from "@/components/LocationSelect/StateSelectComposition";
import CountrySelectComposition from "@/components/LocationSelect/CountrySelectComposition";
import EsuSelectComposition from "@/components/LocationSelect/EsuSelectComposition";
import {EMAIL_REGEX} from "@/ValidConstants";
import {isEmpty, isEqual} from "lodash/lang";
import {differenceWith, fromPairs} from "lodash/array";
import {toPairs} from "lodash/object";
import Swal from "sweetalert2";
import {capitalize} from "lodash/string";
import {
  isDistrictAdmin,
  isNde,
  isParaEducatorCollegeStudentOrEarlyChildhood,
  isSchoolUser,
  isSuperUser,
  validateEmailIfNde
} from "@/RoleCheck";
import StatusSelect from "@/components/Status/StatusSelect";
import router from "@/router";
import OutNeOrganizationDropdown from "@/components/InstitutionForm/OutNeOrganizationDropdown";
import {changeEnrollmentByUserId} from "@/Helpers/ApiCalls/UserAPICalls";
import UserDeleteModal from "@/components/User/UserDelete/UserDeleteModal";
import PasswordResetComponent from "@/components/PasswordReset/PasswordResetComponent.vue";
import SchoolSelectComposition from "@/components/LocationSelect/SchoolSelectComposition.vue";
import DistrictSelectComposition from "@/components/LocationSelect/DistrictSelectComposition.vue";
import LoadingModal from "@/components/Generics/LoadingModal.vue";


export default {
  name: "EditUserComposition",
  components: {
    LoadingModal,
    DistrictSelectComposition,
    SchoolSelectComposition,
    PasswordResetComponent,
    UserDeleteModal,
    OutNeOrganizationDropdown,
    StatusSelect,
    // OutNeInstitutionComposition,
    EsuSelectComposition,
    CountrySelectComposition,
    StateSelectComposition,
    JobTypeDropdown
  },
  props: {
    idUser: String
  },
  setup(props) {
    const store = useStore();
    const authUser = computed(() => store.getters['auth/user']);
    const authToken = computed(() => store.getters['auth/authenticated']);
    const editingUser = ref({});
    const isLoading = ref(true);
    const defaultCountry = ref("US");
    const user = ref({});
    const oldUserValues = ref({});
    const isValidEmail = ref({});
    const msg = ref([]);
    const rerenderAccountStatus = ref(false);
    const updateEnrollment = ref(false);
    const showDeleteModal = ref(false);
    const showDeleteButton = ref(false);
    const showChangePasswordModal = ref(false);


    onBeforeMount(() => {
      if (authUser.value === null) location.replace("/")
      getEditingUser();
      // getUser();
      isLoading.value = false;
    })

    async function getEditingUser() {
      let get_uri = API_URL + "/user/byId?_idUser=" + authUser.value.idUser;
      await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        editingUser.value = result.data;
        defaultCountry.value = editingUser.value.countryCode;
      }).finally(() => {
        getUser();
      })
    }

    async function getUser() {
      let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(atob(props.idUser));

      await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        user.value = result.data;
        updateEnrollment.value = user.value.enrollmentType.enrollmentSelectValue === "supervisorTraining";
        oldUserValues.value = {...user.value}
      }).finally(() => {
        if (isNde(editingUser.value.roleHierarchyId)) router.replace({
          name: 'ViewUser',
          params: {idUser: props.idUser}
        });


        let da_edit_admin = (isDistrictAdmin(editingUser.value.roleHierarchyId) && isDistrictAdmin(user.value.roleHierarchyId));

        let supervising;

        if (user.value.inNebraska) {
          switch (editingUser.value.roleHierarchyId) {
            case 2:
              supervising = user.value.idEsu === editingUser.value.idEsu;
              break;
            case 3:
              supervising = user.value.idDistrict === editingUser.value.idDistrict;
              break;
            case 4:
              supervising = user.value.idSchool === editingUser.value.idSchool;
              break;
            default:
              supervising = false;
              break;
          }
        } else {
          supervising = user.value.idOutstateOrganization === editingUser.value.idOutstateOrganization;
        }

        let can_edit = (editingUser.value.roleHierarchyId < user.value.roleHierarchyId && supervising)
            || da_edit_admin || isSuperUser(editingUser.value.roleHierarchyId) || parseInt(atob(props.idUser)) === editingUser.value.idUser;
        showDeleteButton.value = isSuperUser(editingUser.value.roleHierarchyId) && user.value.idUser !== editingUser.value.idUser;
        if (!can_edit) router.replace({name: 'ViewUser', params: {idUser: props.idUser}});

      })
    }

    watch(() => user.value.email, () => {
      validateEmail(user.value.email);
    })

    watch(() => user.value.roleHierarchyId, () => {
        validateEmail(user.value.email);
    })

    function changeState(event) {
      user.value.stateCode = event;
      user.value.inNebraska = event === 'NE';

      if (isNde(user.value.roleHierarchyId) && !user.value.inNebraska) {
        user.value.roleHierarchyId = -1;
      }
    }

    function changeCountry(event) {
      user.value.countryCode = event;
      user.value.stateCode = event === 'US' ? oldUserValues.value.stateCode : -1;
      user.value.inNebraska = event === 'US' ? user.value.stateCode === 'NE' : false;

      if (isNde(user.value.roleHierarchyId) && !user.value.inNebraska) {
        user.value.roleHierarchyId = -1;
      }
    }

    function changeEsu(event) {
      user.value.idEsu = parseInt(event);
      changeDistrict(-1);
    }

    function changeDistrict(event) {
      user.value.idDistrict = parseInt(event);
      changeSchool(-1);
    }

    function changeSchool(event) {
      user.value.idSchool = parseInt(event);
    }

    function changeOutState(event) {
      user.value.outStateOrgName = event;
    }

    function validateEmail(value) {
      isValidEmail.value = EMAIL_REGEX.test(value);
      if (!isValidEmail.value) {
        msg.value['email'] = "Invalid email address format"
      } else if (isNde(user.value.roleHierarchyId)) {
        msg.value["email"] = validateEmailIfNde(value) ? "" : "To update the user with that title you must have a Nebraska state email account.";
      } else {
        delete msg.value['email']
      }
    }

    function userStatusHelper(statusCode) {
      Swal.fire({
        title: 'Change User Access?',
        text: 'Changing user access will remove their access to Project Para and the Para Canvas course.\nAre you sure?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
        icon: 'warning'
      }).then((result) => {
        if (result.isConfirmed) {
          changeUserStatus(statusCode);
        } else {
          rerenderAccountStatus.value = true;
          Swal.fire({
            title: 'Did not update user access'
          }).then(() => {
            user.value.statusFlag.statusCode = oldUserValues.value.statusFlag.statusCode;
            rerenderAccountStatus.value = false;
          })
        }
      })
    }

    async function changeUserStatus(statusCode) {
      const put_uri = API_URL + "/user/changeStatus";
      await axios.put(put_uri, {
            idUser: atob(props.idUser),
            statusCode: statusCode
          },
          {
            headers: {
              Authorization: "Bearer " + props.authToken
            }
          }).then(() => {
        Swal.fire({
          title: 'Updated User',
          icon: 'success'
        }).then((result) => {
          router.push({name: 'ViewUser', params: {idUser: props.idUser}})

        })
      })
    }

    function changeRoleHierarchy(event) {
      user.value.roleHierarchyId = parseInt(event);
      if (user.value.inNebraska) {
        if (isSchoolUser(user.value.roleHierarchyId) && !isSchoolUser(oldUserValues.value.roleHierarchyId)) {
          changeSchool(-1);
        } else if (isSchoolUser(user.value.roleHierarchyId) && isSchoolUser(oldUserValues.value.roleHierarchyId)) {
          changeSchool(user.value.idSchool);
        } else if (!isDistrictAdmin(user.value.roleHierarchyId)) {
          changeDistrict(-1);
        }
      }
    }

    function deleteButtonClicked() {
      Swal.fire({
        title: `Delete ${user.value.firstName} ${user.value.lastName}?`,
        html: `<span style="font-weight: bold;">Deleting a user is irreversible.</span>
                <p>Continue?</p>`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          showDeleteModal.value = true;
        }
      })
    }

    function retryPasswordReset(message) {
      showChangePasswordModal.value = false;
      Swal.fire({
        title: "Something Went Wrong!",
        text: message,
        icon: "error",
        confirmButtonText: "Okay",
      }).then(() => {
        showChangePasswordModal.value = true;
      })
    }

    function resetEdits() {
      user.value = {...oldUserValues.value};
    }

    function cancelEdit() {
      router.back();
    }

    const showSchool = computed(() => {
      if (!user.value.inNebraska) return false;
      return user.value.roleHierarchyId === 4 || user.value.roleHierarchyId === 5 || user.value.roleHierarchyId === 6;
    });

    const showDistrict = computed(() => {
      if (!user.value.inNebraska || user.value.roleHierarchyId === 7) return false;
      return user.value.roleHierarchyId >= 3;
    });

    const esuCleared = computed(() => {
      if (!user.value.inNebraska || isNde(user.value.roleHierarchyId)) return false;
      return user.value.roleHierarchyId >= 1;
    });

    const districtDisabled = computed(() => {
      if (isSuperUser(editingUser.value.roleHierarchyId)) return false;
      return editingUser.value.roleHierarchyId >= 3 || selfEditing.value;
    })

    const schoolDisabled = computed(() => {
      if (isSuperUser(editingUser.value.roleHierarchyId)) return false;
      return editingUser.value.roleHierarchyId >= 4 || selfEditing.value;
    })

    const selfEditing = computed(() => {
      return editingUser.value.idUser === user.value.idUser;
    })

    const locationDisabled = computed(() => {
      return !isSuperUser(editingUser.value.roleHierarchyId);
    })

    const canSubmit = computed(() => {
      let email_cleared = isEmpty(msg.value) && user.value.email !== "";
      let role_cleared = false;

      if (user.value.roleHierarchyId !== -1) {
        if (esuCleared.value) {
          role_cleared = user.value.idEsu !== -1;
        }
        if (showDistrict.value) {
          role_cleared = user.value.idDistrict !== -1;
        }
        if (showSchool.value) {
          role_cleared = user.value.idSchool !== -1;
        }
        if (!user.value.inNebraska) {
          role_cleared = user.value.outStateOrgName !== "";
        }
        if (isNde(user.value.roleHierarchyId)) {
          role_cleared = user.value.idEsu === 22;
        }
      }
      return email_cleared && role_cleared && !isEqual(user.value, oldUserValues.value);
    })

    function submitEdit() {
      let diff = differenceWith(toPairs(user.value), toPairs(oldUserValues.value), isEqual);

      let alert_msg = prepareDiffMessage(fromPairs(diff))


      if (alert_msg !== "") {
        Swal.fire({
          title: "Confirm Changes to User",
          html: alert_msg,
          confirmButtonText: "I Confirm These Changes",
          cancelButtonText: "Cancel",
          passwordShowIcon: "warning",
          showCancelButton: true,
          allowEscapeKey: false
        }).then((didSubmit) => {
          if (didSubmit.isConfirmed) {
            Swal.fire({
              title: "Confirmed",
              text: "Submitted Changes to User",
              passwordShowIcon: "success",
              confirmButtonText: "OK",
              icon: 'success'
            }).then((confirmed) => {
              if (confirmed) {
                isLoading.value = true;
                postSubmit();
              }
            })
          } else if (didSubmit.isDismissed) {
            Swal.fire({
              title: "Canceled User Edit",
              text: "This user was not updated",
              icon: 'warning'
            })
          }
        })
      }
    }

    const prepareDiffMessage = (diff) => {

      let diff_keys = [];

      for (const [key, val] of Object.entries(diff)) {
        let key_name = "";
        if (key === 'email') {
          key_name = 'Email';
        }

        if (key.includes('role')) {
          key_name = 'Job';
        }

        if (key.includes('District')) {
          key_name = 'District'
        }

        if (key.includes('School')) {
          key_name = 'School'
        }

        if (key.includes('Esu')) {
          key_name = 'ESU'
        }

        if (key.includes('outState')) {
          key_name = 'Organization'
        }

        if (key.includes('country')) {
          key_name = 'Country'
        }
        if (key.includes('state')) {
          key_name = 'State'
        }

        if (key.includes('out')) {
          key_name = 'Organization';
        } else if (key.includes('Name')) {
          let diff_arr = key.split(/(?=[A-Z])/);
          for (let i in diff_arr) {
            diff_arr[i] = capitalize(diff_arr[i]);
          }

          key_name = diff_arr.join(' ');
        }
        diff_keys.push(key_name)
      }

      return "<br/> You are about to edit the " + diff_keys.join(', ') + " for this user."
    }

    async function postSubmit() {
      let post_uri = API_URL + "/user/v2/editUser";

      let post_body = {
        firstName: user.value.firstName,
        lastName: user.value.lastName,
        email: user.value.email,
        countryCode: user.value.countryCode,
        stateCode: user.value.stateCode,
        educationalInstitution: user.value.outStateOrgName,
        idEsu: user.value.idEsu,
        idDistrict: showDistrict.value ? user.value.idDistrict : -1,
        idSchool: showSchool.value ? user.value.idSchool : -1,
        roleHierarchyId: user.value.roleHierarchyId,
        idUser: user.value.idUser,
        canvasId: user.value.canvasId,
      }

      await axios.post(post_uri, post_body, {
        headers: {
          "Authorization": "Bearer " + authToken.value,
          "Content-Type": "application/json"
        }
      }).then((result) => {
        if (result.status === 200) {
          isLoading.value = false;

          router.push({name: 'ViewUser', params: {idUser: props.idUser}})
        }
      })
    }

    function changeEnrollmentHelper(changeEnrollment = false) {

      const enrollmentType = changeEnrollment ? "supervisorTraining" : "none";

      Swal.fire({
        title: 'Change Enrollment',
        text: `This will change what course(s) ${user.value.firstName} ${user.value.lastName} is registered for.`,
        confirmButtonText: 'Okay',
        cancelButtonText: 'Cancel',
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          changeUserEnrollment(enrollmentType);
        } else {
          Swal.fire({
            title: 'Enrollment Not Updated',
            text: `${user.value.firstName} ${user.value.lastName}'s course(s) were not changed`,
            confirmButtonText: 'Okay',
          }).then(() => {
            getUser();
          })
        }
      })
    }

    async function changeUserEnrollment(enrollmentType) {
      await axios.put(changeEnrollmentByUserId(parseInt(atob(props.idUser))), null, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        },
        params: {
          _selectionValue: enrollmentType,
          _actionType: "",
        }
      }).then(() => {
        getUser();
      })
    }

    const showInstructor = computed(() => {
      return editingUser.value.roleHierarchyId <= 3 || editingUser.value.idUser === user.value.idUser;
    })

    const showDistrictAdmin = computed(() => {
      return editingUser.value.roleHierarchyId <= 3;
    })

    const showEsuAdmin = computed(() => {
      return editingUser.value.roleHierarchyId === 1 || editingUser.value.idUser === user.value.idUser;
    })

    const enrollmentLabel = computed(() => {
      return user.value.roleHierarchyId !== 5 && user.value.roleHierarchyId !== -1 ? "Supervisor Course Enrollment" : "Paraeducator Course Enrollment";
    })

    const showCanvasIdSection = computed(() => {
      return editingUser.value.roleHierarchyId === 1;
    })

    const showChangePasswordButton = computed(() => {
      return selfEditing.value || isSuperUser(editingUser.value.roleHierarchyId);
    })

    const enrollmentCheckBoxEnabled = computed(() => {
      return !(isParaEducatorCollegeStudentOrEarlyChildhood(user.value.roleHierarchyId) && user.value.statusFlag.statusCode === 'A');
    })

    function routeToUserMerge() {
      router.push({name: 'MergeSearch', params: {userBeingMergedId: props.idUser}})
    }

    return {
      authUser,
      authToken,
      isLoading,
      user,
      editingUser,
      msg,
      canSubmit,
      oldUserValues,
      showDistrict,
      showSchool,
      showInstructor,
      showDistrictAdmin,
      districtDisabled,
      schoolDisabled,
      selfEditing,
      locationDisabled,
      rerenderAccountStatus,
      showEsuAdmin,
      updateEnrollment,
      enrollmentLabel,
      showDeleteModal,
      showDeleteButton,
      showChangePasswordButton,
      showChangePasswordModal,
      showCanvasIdSection,
      enrollmentCheckBoxEnabled,
      changeState,
      changeCountry,
      changeRoleHierarchy,
      changeEsu,
      changeDistrict,
      changeSchool,
      changeOutState,
      submitEdit,
      userStatusHelper,
      changeEnrollmentHelper,
      deleteButtonClicked,
      routeToUserMerge,
      retryPasswordReset,
      resetEdits,
      cancelEdit,
    }
  }
}
</script>

<style scoped>
span {
  color: red;
}

card {
  border: 1px solid rgba(0, 0, 0);
  border-radius: 0;
  padding: 1%;
}
</style>