<template>
  <div>
    <select class="form-select" v-model="selectedStatus" @change="updateStatus($event)" style="height: 41px;">
      <option v-for="status in statuses" :key="status" :value="status.statusCode">{{status.statusDescription}}</option>
    </select>
  </div>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import {API_URL} from "../../../Constants";
import axios from "axios";

export default {
  name: "StatusSelect",
  props: {
    defaultStatus: String,
    rerender: Boolean
  },
  setup(props, context) {
    const statuses = ref([]);
    const selectedStatus = ref('D');

    onBeforeMount(() => {
      getStatuses();
    })

    watch(() => props.defaultStatus, () => {
      selectedStatus.value = props.defaultStatus;
    })

    watch(() => props.rerender, () => {
      forceRerender();
    })

    function forceRerender() {
      getStatuses();
    }

    async function getStatuses(){
      let get_uri = API_URL + "/status/all";
      await axios.get(get_uri)
          .then((result) => {
            statuses.value = result.data;
          }).finally(() => {
            if (props.defaultStatus !== null && props.defaultStatus !== undefined) {
              selectedStatus.value = props.defaultStatus;
            }
          })
    }

    function updateStatus(event) {
      selectedStatus.value = event.target.value;
      context.emit("selectedStatus", selectedStatus.value);
    }

    return {
      selectedStatus,
      statuses,
      updateStatus
    }
  }
}
</script>

<style scoped>

</style>