<template>
    <div>
        <label for="stateSelect">Select a State<span>*</span></label>
        <select class="form-select" v-model="selectedState" @change="changeVal($event)" :disabled="stateDisabled"
                id="selectedState" style="height: 41px;" :tabindex="tabIdx">
            <option value="0">Select a State</option>
            <option
                    v-for="state in states"
                    :value="state.stateShort"
                    :key="state.stateId"

            >{{ state.stateName }}
            </option>
        </select>
        <transition name="fade">
            <div v-if="selectedState === 'NE' && showEsu">
                <EsuSelectComposition
                        :idEsu="selectedEsu"
                        :idDistrict="selectedDistrict"
                        :idSchool="selectedSchool"
                        :selfRegistering="selfRegistering"
                        :showDistrict="showDistrict"
                        :show-school="showSchool"
                        :esu-disabled="esuDisabled"
                        :district-disabled="districtDisabled"
                        @selectedEsu="updateEsu($event)"
                        @selectedDistrict="updateDistrict($event)"
                        @selectedSchool="updateSchool($event)"
                        :tab-idx="tabIdx + 1"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';
import {computed, onBeforeMount, ref, watch} from "vue";
import {API_URL} from "../../../Constants";
import EsuSelectComposition from "@/components/LocationSelect/EsuSelectComposition";

export default {
	name: "StateSelectComposition",
	components: {EsuSelectComposition},
	props: {
		idEsu: Number,
		idDistrict: Number,
		idSchool: Number,
		showSchool: Boolean,
		showDistrict: Boolean,
		esuDisabled: Boolean,
		districtDisabled: Boolean,
		schoolDisabled: Boolean,
		stateDisabled: Boolean,
		selfRegistering: Boolean,
		defaultState: String,
		showEsu: Boolean,
		tabIdx: {
			type: Number,
			required: false,
			defaut: 1,
		}
	},
	emits: ["selectedState", "selectedDistrict", "selectedSchool", "selectedEsu"],
	setup(props, context) {
		let states = ref({});
		let selectedState = ref("");
		let selectedEsu = ref(props.idEsu !== undefined ? props.idEsu : -1);
		let selectedDistrict = ref(props.idDistrict !== undefined ? props.idDistrict : -1);
		let selectedSchool = ref(props.idSchool !== undefined ? props.idSchool : -1);


		onBeforeMount(() => {
			getStates();
			selectedState.value = props.defaultState === null ? "NE" : props.defaultState;
			if (props.idEsu !== -1) {
				selectedEsu.value = props.idEsu
			}

			if (props.idDistrict !== -1) {
				selectedDistrict.value = props.idDistrict;
			}

			if (props.idSchool !== -1) {
				selectedSchool.value = props.idSchool;
			}
		})

		watch(() => props.idEsu, (first, second) => {
			selectedEsu.value = props.idEsu;
		})

		watch(() => props.idDistrict, (first, second) => {
			selectedDistrict.value = props.idDistrict;
		})

		watch(() => props.idSchool, (first, second) => {
			selectedSchool.value = props.idSchool;
		})

		watch(() => props.defaultState, (first, second) => {
			selectedState.value = props.defaultState;
		})

		function setSelectedValues() {

		}

		async function getStates() {
			await axios.get(API_URL + "/state/all")
				.then((result) => {
					states.value = result.data;
				})
		}

		function changeVal(event) {

			context.emit(event.target.id, event.target.value);
		}

		function updateEsu(event) {
			context.emit("selectedEsu", event);
		}

		function updateDistrict(event) {
			context.emit("selectedDistrict", event);
		}

		function updateSchool(event) {
			selectedSchool.value = parseInt(event);
			context.emit("selectedSchool", selectedSchool.value);
		}

		return {
			states,
			selectedState,
			changeVal,
			updateEsu,
			updateDistrict,
			updateSchool,
			selectedEsu,
			selectedDistrict,
			selectedSchool,
		}
	}
}
</script>

<style scoped>
span {
    color: red;
}
</style>