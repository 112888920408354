<template>
  <div>
    <label>Educational Organization<span style="color: red;">*</span></label>
    <select class="form-select" v-model="selectedOrganization" style="height: 41px;" @change="updateOrganizationalOrganization($event)">
      <option :value="'-1'">Select an Educational Organization</option>
            <option :value="'0'" v-if="showNotListed">Not Listed</option>
      <option v-for="organization in organizations" :key="organization" :value="organization.organizationName">
        {{ organization.organizationName }}
      </option>
    </select>
  </div>
</template>

<script>
import {ref, onBeforeMount, watch} from "vue";
import axios from "axios";
import {API_URL} from "../../../Constants";

export default {
  name: "OutNeOrganizationDropdown",
  props: {
    countryCode: String,
    stateCode: String,
    refreshOrganizations: Boolean,
    defaultOrganization: String,
    showNotListed: Boolean
  },
  setup(props, context) {
    const organizations = ref([]);
    const selectedOrganization = ref("-1");

    onBeforeMount(() => {
      getOrganizations();
    })

    watch(() => props.countryCode, () => {
      getOrganizations();
    })

    watch(() => props.stateCode, () => {
      getOrganizations();
    })

    watch(() => props.refreshOrganizations, () => {
      getOrganizations();
    })

    watch(() => props.defaultOrganization, () => {
      // console.log(props.defaultOrganization)
      selectedOrganization.value = props.defaultOrganization;
    })

    async function getOrganizations() {
      let get_uri = API_URL + "/outNeOrganization/";

      if (props.countryCode !== 'US') {
        get_uri += "getByCountry?_countryCode=" + props.countryCode;
      } else {
        get_uri += "getByState?_stateShort=" + props.stateCode;
      }

      await axios.get(get_uri)
          .then((result) => {
            organizations.value = result.data.sort((a, b) => a.organizationName > b.organizationName ? 1 : -1);
          }).finally(() => {
            if (props.defaultOrganization !== "-1") {
              selectedOrganization.value = props.defaultOrganization;
            }
          })
    }

    function updateOrganizationalOrganization(event) {
      selectedOrganization.value = event.target.value;
      context.emit("educationalInstitution", selectedOrganization.value);
    }

    return {
      organizations,
      selectedOrganization,
      updateOrganizationalOrganization
    }
  }
}
</script>

<style scoped>

</style>